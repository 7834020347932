export default {
  name: 'Footer',
  data() {
    return {
      nav: [
        {
          title_key: 'global.nav_impressum',
          path: '/masthead',
        },
        {
          title_key: 'global.nav_about',
          path: '/about',
        }
      ]
    }
  }
}