export default {
  data: () => ({
    valid: false,

    // Fields
    name: '',
    email: '',
    instagram: '',

    characterType: 0,

    doctorProperties: '',
    docImage1: null,
    docImage2: null,
    docImage3: null,
    docImage4: null,

    image: null,

    termsAccepted: false,
    creditsWanted: false,

    // Field rules
    nameRules: [
      v => v == '' || v != null && v.length <= 32 || 'Name too long',
    ],
    emailRules: [
      v => v == '' || v != null && /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(v) || 'Invalid email',
    ],
    instagramRules: [
      v => v == '' || v != null && /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/.test(v) || 'Invalid Instagram account',
    ],
    uploadRules: [
      v => this.characterType != 0 && v != null || 'Image required',
      v => this.characterType != 0 && v != null && ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(v.type) || 'Only jpg, jpeg, png oder gif allowed.',
      v => this.characterType != 0 && v != null && v.size < 10485760 || 'Maximal 10 MB erlaubt',
    ],
    uploadRulesMultiple: [
      v => this.characterType == 0 && v != null || 'Image required',
      v => this.characterType == 0 && v != null && ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(v.type) || 'Only jpg, jpeg, png oder gif allowed.',
      v => this.characterType == 0 && v != null && v.size < 10485760 || 'Maximal 10 MB erlaubt',
    ],
    propertiesRules: [
      v => !!v || 'Required'
    ],
    termsRules: [
      v => v || 'Required',
    ],

    snackbar: false,
    snackbarText: '',
    snackbarColor: 'green'

  }),
  methods: {
    submit() {
      if (!this.valid) return;
      const data = new FormData();
      data.append('name', this.name);
      data.append('email', this.email);
      data.append('instagram', this.instagram)

      data.append('characterType', this.characterType)
      if(this.characterType == 0) {
        data.append('doctorProperties', this.doctorProperties)
        data.append('docImage1', this.docImage1)
        data.append('docImage2', this.docImage2)
        data.append('docImage3', this.docImage3)
        data.append('docImage4', this.docImage4)
      } else {
        data.append('image', this.image)
      }

      data.append('creditsWanted', this.creditsWanted)
      data.append('termsAccepted', this.termsAccepted)

      const req = new XMLHttpRequest();
      req.open("POST", "/upload.php", true);
      req.addEventListener('error', () => {
        this.snackbarText = this.$t('error.upload');
        this.snackbarColor = 'red';
        this.snackbar = true;
      })
      req.addEventListener('load', () => {
        if (req.status != 200) return;
        this.snackbarText = this.$t('upload.upload_success');
        this.snackbarColor = 'green';
        this.snackbar = true;

        // Reset the form (We have to set every field to a default value because 'undefined', from uploadForm.reset(), would throw errors.)
        this.$refs.uploadForm.reset();
        this.name = '';
        this.email = '';
        this.instagram = '';
        this.characterType = 0;
        this.doctorProperties = '';
        this.docImage1 = null;
        this.docImage2 = null;
        this.docImage3 = null;
        this.docImage4 = null;
        this.image = null;
        this.termsAccepted = false;
        this.creditsWanted = false;
      })
      req.send(data);
    }
  },
  computed: {
    charTypes() {
      return this.$t('upload.label_type', { returnObjects: true }).map( (ct, i) => { return {title: ct, value: i }; })
    },
    errRequired() {
      return this.$t('error.field_empty')
    }
  }
}