import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import i18n from './i18n'
import './assets/tailwind.css'

Vue.config.productionTip = false
Vue.config.title = 'Fuck titles' //??? Okay

new Vue({
  vuetify,
  router,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')
