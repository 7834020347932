import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'

// Routes
import Home from '@/views/home/Home.vue'
import About from '@/views/about/About.vue'
import Masthead from '@/views/masthead/Masthead.vue'
import Upload from '@/views/upload/Upload.vue'
import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${ window.localStorage.getItem('locale') || i18n.fallbackLocale}` // Redirect to current language
  },{
    path: '/:language',
    component: {
      render(comp) { return comp('router-view') }
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: 'about',
        name: 'About',
        component: About
      },
      {
        path: 'masthead',
        name: 'Masthead',
        component: Masthead
      },
      {
        path: 'upload',
        name: 'Upload',
        component: Upload
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const BASE_NAME = 'Crazy Hospital Quartett'
router.beforeEach((to, from, next) => {
  // Handle language
  let locale = to.params.language || i18n.fallbackLocale
  if(!i18n.availableLocales.includes(locale)) {
    // Send user to fallback locale if not existing locale given.
    next(`/${i18n.fallbackLocale}`);
    return;
  }
  i18n.locale = locale;


  // Handle page title
  document.title = `${to.name} - ${BASE_NAME}` || BASE_NAME;
  next();
});

export default router
