import LanguageSwitcher from '@/components/languageSwitcher/LanguageSwitcher.vue'

export default {
  name: 'Navbar',
  components: {
    LanguageSwitcher
  },
  data() {
    return {
      menuOpen: false,
      nav: [
        {
          title_key: 'global.nav_home',
          path: '',
        },
        {
          title_key: 'global.nav_upload',
          path: '/upload',
        }
      ]
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    }
  }
}