import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [ clickaway ],
  data() {
    return {
      isVisible: false
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible
    },
    hideDropdown() {
      this.isVisible = false
    },
    setLocale(locale) {
      this.hideDropdown()
      if(locale == this.$i18n.locale) return;

      window.localStorage.setItem('locale', locale);
      this.$i18n.locale = locale;
      this.$router.push({ params: { language: locale } });
    }
  },
  computed: {
    locales() {
      return this.$i18n.availableLocales.filter( locale => locale != this.$i18n.locale);
    }
  }
}